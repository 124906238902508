<template>
  <div class="honeycomb">
    <router-view />
    <ScrollTop />
  </div>
</template>

<script>
import ScrollTop from "@/components/ScrollTop.vue";
export default {
  components: { ScrollTop },
  data() {
    return {};
  },
};
</script>

<style scoped>
.honeycomb {
  height: 100%;
  width: 100%;
  background-image: url("../../assets/conteudos_ser_atingido_background.png");
  background-size: cover;
  background-color: #f9f7ed;
}
</style>
